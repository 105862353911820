import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralMap from '../components/generalMap';

const ProviderPage = ({pageContext }) => {
  
  return (
    <Layout>
      <SEO title={pageContext.provider.name} />
      <h1>{pageContext.provider.name}</h1>
      <dl>
          <dt>Website</dt>
          <dd><a href={pageContext.provider.website}>{pageContext.provider.website}</a></dd>
      </dl>
      <dl>
          <dt>Links to: </dt>
          <dd><a href={pageContext.provider.app.ios}>Apple AppStore (iOS)</a> <a href={pageContext.provider.app.android}>Google PlayStore  (Android)</a></dd>
          {/* <dd></dd> */}
      </dl>
      <GeneralMap providerFilter={[pageContext.provider.slug]} />
    </Layout>
  )
}

export default ProviderPage
